<template>
  <div v-if="fetchedQAList.length > 0">
    <CommonListLabel
      v-if="placement === 'side'"
      :label="label"
      :link="`${currentPaper}/${labelLinkUrl}`"
    />
    <CommonDivider />
    <ul>
      <template v-for="(qaArticle, index) in fetchedQAList" :key="index">
        <li>
          <QATeaser :qa-article="qaArticle" :placement="placement" />
          <CommonDivider />
        </li>
      </template>
    </ul>

    <div v-if="showMore && moreToShow" class="flex justify-center">
      <CommonButton @click="loadMore">
        {{ $t('ShowMore') }}
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { QADto } from '~/typesAuto/apicore/v1'

const props = withDefaults(
  defineProps<{
    count?: number
    typeId?: number
    placement?: string
    label: string
    labelLink: string
    showMore?: boolean
  }>(),
  {
    count: 5,
    placement: 'side',
    showMore: false,
  }
)

const currentPage = ref(1)
const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()

const labelLinkUrl = computed(() => {
  return nuxtApp.$t(props.labelLink)
})

const currentPaper = computed(() => {
  if (indexStore.currentPaper && indexStore.currentPaper?.RecordId !== 1) {
    return '/' + indexStore.currentPaper?.Url
  } else {
    return ''
  }
})
const fetchedQAList = useState<QADto[]>(
  `${indexStore.currentPaperIdentifier}-${currentPage.value}-${props.typeId}-${props.count}`,
  () => []
)

const { data: fetchedQA, refresh } = await useAsyncData(
  `${indexStore.currentPaperIdentifier}-${currentPage.value}-${props.typeId}`,
  async () => {
    const rawResponse = await nuxtApp.$api.content.QAList(
      indexStore.currentPaperIdentifier,
      props.count,
      currentPage.value,
      props.typeId
    )

    if (rawResponse.data) {
      if (props.showMore) {
        fetchedQAList.value = [...fetchedQAList.value, ...rawResponse.data]
      } else {
        fetchedQAList.value = rawResponse.data
      }
    }

    return rawResponse
  }
)

const moreToShow = computed(() => {
  return fetchedQAList.value.length < (fetchedQA.value?.totalCount ?? 0)
})

const loadMore = () => {
  currentPage.value += 1
  refresh()
}
</script>
